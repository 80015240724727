<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <ValidationObserver ref="observerRef" v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(onSubmit)">
            <div class="card card-custom card-sticky">
              <div class="card-header" style="">
                <div class="card-title">
                  <h3 class="card-label">{{ $t("ADD.ADD_NEW_WORKER") }}</h3>
                </div>
                <CardToolbar
                  @submitEvent="onSubmit"
                  back-url="/workers/list"
                  buttonText="Add Worker"
                ></CardToolbar>
              </div>
              <div class="card-body p-0">
                <div
                  class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                >
                  <div class="col-xl-12 col-xxl-10">
                    <div class="row justify-content-center">
                      <div class="col-xl-9">
                        <h5 class="text-dark font-weight-bold mb-10">
                          {{ $t("WORKERS.PERSONAL_DETAILS") }}
                        </h5>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.NAME") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                id="firstName"
                                class="form-control form-control-lg"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="worker.firstName"
                                name="firstName"
                                type="text"
                                :placeholder="$t('GENERAL.NAME')"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.LAST_NAME") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                class="form-control form-control-lg"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="worker.lastName"
                                name="lastName"
                                type="text"
                                :placeholder="$t('GENERAL.LAST_NAME')"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.ADDRESS") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="worker.address"
                                name="address"
                                type="text"
                                :placeholder="$t('GENERAL.ADDRESS')"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <validation-provider
                          rules="required|numeric|min:11|max:11"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.OIB") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <input
                                class="form-control"
                                :class="{ 'is-invalid': errors[0] }"
                                v-model="worker.oib"
                                name="oib"
                                type="text"
                                :placeholder="$t('GENERAL.OIB')"
                              />
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.PASSPORT") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <input
                              class="form-control"
                              v-model="worker.passport"
                              name="passport"
                              type="text"
                              :placeholder="$t('GENERAL.PASSPORT')"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.MOBILE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="la la-phone"></i
                                ></span>
                              </div>
                              <input
                                class="form-control"
                                v-model="worker.mob"
                                name="mobileNumber"
                                type="number"
                                :placeholder="$t('GENERAL.MOBILE')"
                              />
                            </div>
                          </div>
                        </div>

                        <validation-provider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-3 col-lg-3 col-form-label"
                              >{{ $t("GENERAL.EMAIL") }}:</label
                            >
                            <div class="col-lg-9 col-xl-9">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text"
                                    ><i class="la la-at"></i
                                  ></span>
                                </div>
                                <input
                                  class="form-control"
                                  :class="{ 'is-invalid': errors[0] }"
                                  v-model="worker.email"
                                  name="email"
                                  type="email"
                                  :placeholder="$t('GENERAL.EMAIL')"
                                />
                              </div>
                            </div>
                          </div>
                        </validation-provider>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.FATHERS_NAME") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <input
                              class="form-control"
                              v-model="worker.fathersName"
                              name="fathersName"
                              type="text"
                              :placeholder="$t('GENERAL.FATHERS_NAME')"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.MOTHERS_NAME") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <input
                              class="form-control"
                              v-model="worker.mothersName"
                              name="mothersName"
                              type="text"
                              :placeholder="$t('GENERAL.MOTHERS_NAME')"
                            />
                          </div>
                        </div>

                        <div class="separator separator-dashed my-10"></div>

                        <h5 class="text-dark font-weight-bold mb-10">
                          {{ $t("GENERAL.SALARY_AND_HOURLYRATE") }}
                        </h5>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.HOURLY_RATE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <div class="input-group">
                              <input
                                class="form-control"
                                v-model.number="worker.hourlyRate"
                                name="hourlyRate"
                                type="text"
                                placeholder="€"
                              />
                              <div class="input-group-prepend">
                                <span class="input-group-text"
                                  ><i class="la la-euro"></i
                                ></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{
                              $t("GENERAL.WORK_PERMIT_EXPIRATION_DATE")
                            }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="worker.workPermitDate"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="workPermitDate"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.MEDICAL_EXPIRATION_DATE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <vc-date-picker
                              v-model="worker.physicalExamDate"
                              :masks="masks"
                            >
                              <template v-slot="{ inputValue, togglePopover }">
                                <div
                                  class="input-group"
                                  @click="togglePopover()"
                                >
                                  <input
                                    class="form-control"
                                    :value="inputValue"
                                    name="workPermitDate"
                                    type="text"
                                  />
                                  <div
                                    class="input-group-prepend cursor-pointer"
                                  >
                                    <span class="input-group-text"
                                      ><i class="la la-calendar"></i
                                    ></span>
                                  </div>
                                </div>
                              </template>
                            </vc-date-picker>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.WORK_SAFETY") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <CSwitch
                              @checkedEvent="toggleWorkProtection"
                              classes="switch-icon"
                              name="workProtection"
                              :is-checked="worker.workProtection"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.FIRST_AID") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <CSwitch
                              @checkedEvent="toggleFirstAid"
                              classes="switch-icon"
                              name="firstAid"
                              :is-checked="worker.firstAid"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.GEDA") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <CSwitch
                              @checkedEvent="toggleGeda"
                              classes="switch-icon"
                              name="geda"
                              :is-checked="worker.geda"
                            />
                          </div>
                        </div>

                        <div class="separator separator-dashed my-10"></div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.BORROWED_TOOLS") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <textarea
                              class="form-control"
                              v-model="worker.tools"
                              rows="4"
                              :placeholder="$t('GENERAL.BORROWED_TOOLS')"
                            ></textarea>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-xl-3 col-lg-3 col-form-label"
                            >{{ $t("GENERAL.NOTE") }}:</label
                          >
                          <div class="col-lg-9 col-xl-9">
                            <textarea
                              class="form-control"
                              v-model="worker.note"
                              rows="4"
                              :placeholder="$t('GENERAL.NOTE')"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CSwitch from "@/view/content/components/switch/CSwitch.vue";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { required, numeric, min, max, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "",
});
extend("numeric", {
  ...numeric,
  message: "",
});
extend("min", {
  ...min,
  message: "",
});
extend("max", {
  ...max,
  message: "",
});
extend("email", {
  ...email,
  message: "",
});

export default {
  name: "NewWorker",
  components: { CardToolbar, CSwitch, ValidationProvider, ValidationObserver },
  data() {
    return {
      worker: {
        firstName: null,
        lastName: null,
        address: null,
        oib: null,
        email: null,
        mob: null,
        passport: null,
        fathersName: null,
        mothersName: null,
        workPermitDate: null,
        physicalExamDate: null,
        workProtection: true,
        firstAid: true,
        geda: true,
        overtimeHr: null,
        weekendHr: null,
        dailyWage: null,
        wage: null,
        nightHr: null,
        hourlyRate: null,
        note: null,
        tools: null,
      },
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_WORKERS"), route: "/workers/list" },
      { title: this.$t("LIST.NEW_WORKER") },
    ]);

    document.getElementById("firstName").focus();
  },
  methods: {
    toggleWorkProtection() {
      this.worker.workProtection = !this.worker.workProtection;
    },
    toggleFirstAid() {
      this.worker.firstAid = !this.worker.firstAid;
    },
    toggleGeda() {
      this.worker.geda = !this.worker.geda;
    },
    onSubmit(type) {
      this.$refs.observerRef.validate().then((isValid) => {
        if (isValid) {
          ApiService.post("workers", this.worker).then(() => {
            this.$notify({
              group: "notification",
              type: "success",
              title: this.$t("WORKER.WORKER_SUCCESS"),
            });
            if (type === "1") {
              setTimeout(() => {
                this.$router.push({ name: "workers-list" });
              }, 1000);
            }
          });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("USER.MANDATORY"),
          });
        }
      });
    },
  },
};
</script>
